import { getFileDetails } from '../services/FileService';

import { CdQueryDefinition } from './types';

export const fileQueries = {
  getFile: (fileId) => ({
    queryKey: ['specificFileById', fileId],
    queryFn: async () => await getFileDetails(fileId),
  }),
} satisfies CdQueryDefinition;
