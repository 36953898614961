import React from 'react';

import {
  CdSelect,
  CdSelectProps,
} from '../../../shared/components/cd-select/CdSelect';
import { useGroups } from '../../../organization/store/organization';
import { I18nService } from '../../../services/I18nService';

export const GroupSelect = (props: Partial<CdSelectProps>) => {
  const { groups, isLoading } = useGroups();

  const groupOptions = (groups ?? []).map((group) => ({
    label: group.name,
    value: group.id,
  }));

  return (
    <CdSelect
      aria-label="groups"
      mode="multiple"
      placeholder={I18nService.getString('Choose one or more groups...')}
      options={groupOptions}
      disabled={isLoading}
      {...props}
      value={isLoading ? [] : props.value}
    />
  );
};
