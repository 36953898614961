import {
  atom,
  selector,
  selectorFamily,
  useRecoilRefresher_UNSTABLE,
} from 'recoil';
import { uniq, uniqBy } from 'lodash';
import {
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';

import { OrganizationFromSearch, SlimUser } from '../types/organization';
import { orderUsers } from '../services/organization.service';
import { resourcesQueries } from '../../shared/queries/resources';
import { groupQueries } from '../../shared/queries/groups';
import { churchQueries } from '../../shared/queries/church';

import { ChurchSettingsState } from './church';

import { registerRecoilRefresher } from '@/app/cdRecoilRefresher';
import cdApp from '@/react/config';
import { mainApi } from '@/react/api';
import { ResourceTypes } from '@/react/shared/models/resource';
import { InterfaceGroups } from '@/react/organization/services/Groups.service';
import { User } from '@/react/calendar/models/calendar';
import { StripeAccount } from '@/react/settings/types/payments-settings.types';
import {
  getLegalInformation,
  getOnboardingUser,
} from '@/react/settings/services/stripe-onboarding.service';
import { Church } from '@/react/calendar/models/calendar';
import { Resources } from '@/react/shared/services/ResourceService';
import { I18nService } from '@/react/services/I18nService';
import { queryClient } from '@/react/shared/utils/query-client';

export const OrganizationsSearchText = atom({
  key: 'OrganizationsSearchText',
  default: '',
});

export const Organizations = atom({
  key: 'Organizations',
  default: selector({
    key: 'OrganizationsInitial',
    get: async () => {
      const { data } = await mainApi.get<OrganizationFromSearch[]>(
        '/organizations/search',
        {
          limit: 10,
          country: cdApp.organization.countryIso2,
        }
      );
      return uniqBy(
        data.concat(cdApp.organization as OrganizationFromSearch),
        'id'
      );
    },
  }),
});

export const SelectedOrganizationId = atom<number>({
  key: 'SelectedOrganizationId',
  default: selector({
    key: 'SelectedOrganizationIdInitial',
    get: () => cdApp.organization.id,
  }),
});

export const SelectedOrganization = atom({
  key: 'SelectedOrganization',
  default: selector({
    key: 'SelectedOrganizationInitial',
    get: () => cdApp.organization,
  }),
});

export const SelectedOrganizationChurches = atom({
  key: 'SelectedOrganizationChurches',
  default: selector({
    key: 'SelectedOrganizationChurchesInitial',
    get: () => cdApp.organization.churches,
  }),
});

export const OrganizationById = selectorFamily<OrganizationFromSearch, number>({
  key: 'OrganizationById',
  get:
    (id) =>
    async ({ get }) =>
      get(Organizations).find((o) => o.id === id),
  set:
    (id) =>
    ({ get, set }, value: OrganizationFromSearch) => {
      const organizations = get(Organizations);

      if (!organizations.find((o) => o.id === id)) {
        set(Organizations, (o) => o.concat(value));
      }
    },
});

export const OrganizationInfoByIdQuery = selectorFamily<
  OrganizationFromSearch,
  number
>({
  key: 'OrganizationInfoByIdQuery',
  get: (id: number) => async () => {
    const res = await mainApi.get<any>(`/organizations/${id}/public`);
    return res.data;
  },
});

/**
 * Atom to bust the cache of the user list for the current organization.
 * This is also used by other selectors to bust their cache if they depend on users.
 */
export const OrganizationUsersCache = atom<number>({
  key: 'OrganizationUsersCache',
  default: 0,
});

export const OrganizationUsers = selector<SlimUser[]>({
  key: 'OrganizationUsers',
  get: async ({ get }) => {
    get(OrganizationUsersCache);
    const { ok, data, originalError } =
      await mainApi.get<SlimUser[]>('/users/slim');
    if (ok) {
      return orderUsers(data);
    }
    throw originalError;
  },
});

export const useOrganizationUsers = () => {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: ['useOrganizationUsers'],
    queryFn: async () => {
      const response = await mainApi.get<User[]>('users');
      if (response.ok) {
        return response.data;
      }
      throw response;
    },
  });

  const refreshUsers = () =>
    queryClient.invalidateQueries({
      queryKey: ['useOrganizationUsers'],
    });

  return { users: query.data || [], refreshUsers, isLoading: query.isLoading };
};

export const useOrganizationUsersSlim = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['useOrganizationUsersSlim'],
    queryFn: async () => {
      const response = await mainApi.get<SlimUser[]>('users/slim');
      if (response.ok) {
        return response.data;
      }
      throw response;
    },
  });

  return { users: data || [], isLoading };
};

export const OrganizationUsersIndexed = selector<{
  [userId: number]: SlimUser;
}>({
  key: 'OrganizationUsersIndexed',
  get: async ({ get }) => {
    const users = get(OrganizationUsers);
    const usersObject = users.reduce(
      (obj, user) => ({
        ...obj,
        [user.id]: user,
      }),
      {}
    );
    return usersObject;
  },
});

export const GetOrganizationUser = selectorFamily<
  {
    name: string;
    picture: string;
    status: 'blocked' | 'active' | 'createdWithoutLogin';
  },
  { id: number }
>({
  key: 'GetOrganizationUser',
  get:
    ({ id }) =>
    async ({ get }) => {
      const allUsers = get(OrganizationUsers);
      const user = allUsers.find((user) => user.id === id);
      if (user) {
        return { name: user.name, picture: user.picture, status: user.status };
      } else {
        return {
          name: I18nService.getString('Unknown'),
          picture: null,
          status: null,
        };
      }
    },
});

export const OrganizationChurches = selector<Church[]>({
  key: 'OrganizationChurches',
  get: async ({ get }) => {
    const data = get(Resources);
    return data
      .filter((item) => item.type === ResourceTypes.CHURCH)
      .map((item) => {
        const { id, color, name } = item;
        return { id, name, color } as Church;
      });
  },
});

export const GetAllGroups = selector<InterfaceGroups[]>({
  key: 'GetAllGroups',
  get: async ({ getCallback }) => {
    const res = await mainApi.get<InterfaceGroups[]>('/groups');
    registerRecoilRefresher(
      GetAllGroups,
      getCallback(
        ({ refresh }) =>
          () =>
            refresh(GetAllGroups)
      )
    );
    if (res.ok) {
      return res.data;
    }
    throw res.originalError;
  },
});

export const useOrganizationSettings = () => {
  const queryClient = useQueryClient();
  const refreshChurchSettings =
    useRecoilRefresher_UNSTABLE(ChurchSettingsState);
  const query = useSuspenseQuery(churchQueries.get());

  const refreshOrganizationSettings = () => {
    queryClient.invalidateQueries(churchQueries.get());
    refreshChurchSettings();
  };

  return { organizationSettings: query.data, refreshOrganizationSettings };
};

export const useGroups = (groupIds?: number[]) => {
  const queryClient = useQueryClient();
  const query = useQuery(groupQueries.getAll());

  const groups = (query.data || []).filter((group) =>
    groupIds?.length ? groupIds?.includes(group?.id) : true
  );
  const groupMemberIds = uniq(groups.map((item) => item?.members || []).flat());
  const myGroups = groups.filter((item) => item.members.includes(cdApp.me.id));
  const myGroupsIds = myGroups.map((item) => item.id);
  const absenseGroups = myGroups.filter((item) => !item.absenceDisabled);

  const groupsWithUserAsMember = (
    userId: number,
    includeGroupsWithAbsenceDisabled = true
  ) =>
    groups.filter(
      (item) =>
        item.members.includes(Number(userId)) &&
        (includeGroupsWithAbsenceDisabled || !item.absenceDisabled)
    );

  const refreshGroups = () => {
    queryClient.invalidateQueries(groupQueries.getAll());
  };

  return {
    groups: query.data,
    absenseGroups,
    myGroupsIds,
    groupMemberIds,
    groupsWithUserAsMember,
    refreshGroups,
    isLoading: query.isLoading,
  };
};

export const useStripeAccount = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['accountDetailsQuery'],
    throwOnError: false,
    queryFn: async () =>
      await mainApi.get<StripeAccount>(
        `/organizations/${cdApp.organization.id}/stripe/account`
      ),
  });

  const invalidate = () =>
    queryClient.invalidateQueries({
      queryKey: ['accountDetailsQuery'],
    });

  const stripeAccountCreated = data && data.ok && data.data.id;
  const stripeOnboardingCompleted = data?.data?.details_submitted;
  const stripeAccountRestricted = !(
    stripeOnboardingCompleted &&
    data?.data?.charges_enabled &&
    data?.data?.payouts_enabled &&
    data?.data?.isVerified
  );

  return {
    stripeAccountData: data?.data,
    stripeAccountCreated,
    stripeOnboardingCompleted,
    stripeAccountRestricted,
    invalidate,
    stripeAccountIsLoading: isLoading,
  };
};

export const useLegalInformation = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['legalInformationQuery'],
    queryFn: async () => getLegalInformation(),
  });
  return { legalInformation: data, legalInformationIsLoading: isLoading };
};

export const useStripeOnboardingUser = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['onboardingUserQuery'],
    queryFn: async () => getOnboardingUser(),
  });
  return {
    stripeOnboardingUser: data,
    stripeOnboardingUserIsLoading: isLoading,
  };
};

export const useResources = () => {
  const queryClient = useQueryClient();
  const query = useQuery(resourcesQueries.getAll());

  const refreshResources = () =>
    queryClient.invalidateQueries(resourcesQueries.getAll());

  return {
    resources: query.data || [],
    isLoading: query.isLoading,
    refreshResources,
  };
};
