import React from 'react';
import styled from 'styled-components';
import { Button, Space, Image } from 'antd';

import { showEditFileModal } from './EditFileModal';

import { CdEditIcon } from '@/react/shared/components/Icons';

const StyledEditComponent = styled.span`
  padding: 0;
  cursor: pointer;
  color: 	#A0A0A0;
  &:hover {
    color: black;
  }
`;
const ImageContainer = styled.div`
 width: 28px;
  height: 28px;
  vertical-align: middle;
  margin-right: 5px;
`;

export const EditFileButton = ({ fileId, tagsList, canEdit }) =>
  canEdit ? (
    <StyledEditComponent
      onClick={() =>
        showEditFileModal({
          fileId,
          tagsList: tagsList,
          tags: tagsList,

          creditSettings: {
            requireImageCredits: true,
          },
          reloadStateAfterResolved: true,
        })
      }
    >
      <CdEditIcon size="lg" />
    </StyledEditComponent>
  ) : null;

export const ImageFileLink = ({
  fileId,
  tagsList,
  title,
  fileThumbnailUrl,
}) => (
  <Button
    type="link"
    style={{ padding: 0 }}
    onClick={() =>
      showEditFileModal({
        fileId,
        tagsList: tagsList,
        tags: tagsList,
        creditSettings: {
          requireImageCredits: true,
        },
        reloadStateAfterResolved: true,
      })
    }
  >
    <Space>
      <ImageContainer>
        <Image
          src={fileThumbnailUrl}
          preview={false}
          style={{ borderRadius: '4px' }}
        />
      </ImageContainer>
      {title}
    </Space>
  </Button>
);
