import { useMutation, useQuery } from '@tanstack/react-query';
import { Form } from 'antd';
import { useEffect } from 'react';

import { updateFile } from '../services/file';
import { UpdateFile } from '../types/images.type';

import { fileQueries } from '@/react/shared/queries/files';
import { handleSuccessMessage } from '@/react/shared/utils';
import { I18nService } from '@/react/services/I18nService';
import { mountingContainer } from '@/react/calendar/file-picker-library/containers/mountContainer';

export const useEditFile = ({ fileId, tags }) => {
  const [form] = Form.useForm();
  const { data, isLoading } = useQuery(fileQueries.getFile(fileId));
  const { update } = useUpdateFile();
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        title: data.title,
        visibility: data.visibility,
        tags: data.tags || tags,
        credits: data.credits,
        altText: data.altText,
        getContainer: mountingContainer,
      });
    }
  }, [data, form, tags]);
  return {
    file: data,
    isLoading,
    form,
    update,
  };
};

export const useUpdateFile = () => {
  const { mutateAsync } = useMutation({
    mutationFn: ({ fileId, payload }: UpdateFile) =>
      updateFile({ fileId, payload }),
    onSuccess: () => {
      handleSuccessMessage(I18nService.getString('File updated successfully.'));
    },
  });
  return { update: mutateAsync };
};
