import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import {
  Col,
  Form,
  Input,
  Row,
  Image,
  Divider,
  Button,
  Typography,
  Space,
  Popover,
} from 'antd';

import { I18nService } from '../../services/I18nService';
import { useEditFile } from '../hooks/useEditFile';

import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import { required } from '@/react/shared/antd/validations/common';
import { CdSelect } from '@/react/shared/components/cd-select/CdSelect';
import {
  CdLink,
  CdCopyrightIcon,
  CdCloudDownloadIcon,
} from '@/react/shared/components/Icons';
import { mountingContainer } from '@/react/calendar/file-picker-library/containers/mountContainer';
import { useGetFilePermissionsQuery } from '@/react/files/store/files';
import cdApp from '@/react/config';
import { getStateUrl } from '@/react/services/StateServiceFactory';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 24px;
  align-items: center;
`;
export interface ModalGroup {
  name: string;
  id: string;
  members: number[];
}

interface EditFileModalProps {
  fileId: number;
  tagsList: string[];
  creditSettings?: any;
  tags: string[];
  reloadStateAfterResolved: boolean;
}

export const showEditFileModal = createCdModal<EditFileModalProps>(
  ({
    fileId,
    tagsList,
    tags,
    setModalProps,
    creditSettings,
    reloadStateAfterResolved,
  }) => {
    const { form, file, update } = useEditFile({ fileId, tags });
    const { filePermissionsQuery } = useGetFilePermissionsQuery();
    const getLink = useCallback(() => {
      if (file?.visibility === 'group') {
        return (
          cdApp.references.appUrl +
          getStateUrl('app.private.intranet.files.view', { id: fileId })
        );
      } else {
        return `${cdApp.config.api.main}/files/${file?.uuid}/view`;
      }
    }, [file?.visibility, file?.uuid, fileId]);
    useEffect(() => {
      setModalProps({
        modalType: ModalType.MODAL,
        title: (
          <>
            <Header>
              <Typography.Text strong>{file?.title}</Typography.Text>
              <Space>
                <Popover
                  content={<div>{getLink()}</div>}
                  trigger="click"
                  placement="left"
                >
                  <Button icon={<CdLink />}>
                    {I18nService.getString('Get link')}
                  </Button>
                </Popover>
                <Button href={file?.downloadUrl} icon={<CdCloudDownloadIcon />}>
                  {I18nService.getString('Download')}
                </Button>
              </Space>
            </Header>
            <Divider />
          </>
        ),
        okButtonProps: { disabled: !file?.permission.canEdit },
        onOk: async () => {
          await form.validateFields().then(async (values) => {
            await update({ fileId, payload: values });
          });
        },
        reloadStateAfterResolved: reloadStateAfterResolved,
      });
    }, [
      file?.downloadUrl,
      file?.permission.canEdit,
      file?.title,
      fileId,
      form,
      getLink,
      reloadStateAfterResolved,
      setModalProps,
      update,
    ]);

    return (
      <>
        <Row gutter={16}>
          <Col span={12}>
            <Image src={file?.fileUrl} />
          </Col>
          <Col span={12}>
            <Form form={form} layout="vertical" name="form_edit_file">
              <Form.Item
                name="title"
                label={I18nService.getString('Title')}
                rules={[required()]}
              >
                <Input disabled={!file?.permission.canEdit} />
              </Form.Item>
              <Form.Item
                name="visibility"
                label={I18nService.getString('Visibility')}
                rules={[required('Visibility is required.')]}
              >
                <CdSelect
                  disabled={
                    !file?.permission.canEdit ||
                    (filePermissionsQuery &&
                      !filePermissionsQuery['change visibility'])
                  }
                  getPopupContainer={mountingContainer}
                  options={[
                    { label: I18nService.getString('Public'), value: 'web' },
                    {
                      label: I18nService.getString('Only share with group'),
                      value: 'group',
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item name="tags" label={I18nService.getString('Tags')}>
                <CdSelect
                  mode="tags"
                  disabled={!file?.permission.canEdit}
                  placeholder={I18nService.getString('Please select tags')}
                  getPopupContainer={mountingContainer}
                  allowClear
                  options={(tagsList || []).map((tag) => ({
                    label: tag,
                    value: tag,
                  }))}
                />
              </Form.Item>
              <Form.Item
                name="credits"
                label={I18nService.getString('Copyright')}
                rules={
                  creditSettings?.requireImageCredits
                    ? [required('Copyright is required')]
                    : []
                }
              >
                <Input
                  placeholder={I18nService.getString(
                    'i.e. Photo by John Smith'
                  )}
                  addonBefore={<CdCopyrightIcon />}
                  disabled={!file?.permission.canEdit}
                />
              </Form.Item>
              <Form.Item
                rules={[required()]}
                name="altText"
                label={I18nService.getString('Alternative text')}
                extra={I18nService.getString(
                  'A brief description of your uploaded image is crucial for European web accessibility compliance. It helps visually impaired users understand the image and improves SEO. Keep the description clear and relevant!'
                )}
              >
                <Input.TextArea disabled={!file?.permission.canEdit} />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </>
    );
  },
  {
    getContainer: mountingContainer,
    modalType: ModalType.MODAL,
    width: 800,
  }
);
