import { Col, Empty, Row, Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import React, { ReactNode } from 'react';

import { I18nService } from '../../../services/I18nService';
import { sortString } from '../../utils/sorter';
import { CdFilterSelect } from '../selects/cd-filter-select/CdFilterSelect';

export interface CdSelectProps extends Omit<SelectProps, 'title'> {
  placeholder?: string;
  emptyText?: string;
  addonAfter?: ReactNode;
  disableSort?: boolean;
  selectType?: 'default' | 'filter';
  title?: (count: number) => string;
}

export function CdSelect({
  allowClear = true,
  showSearch = true,
  addonAfter,
  emptyText,
  selectType,
  title,
  ...rest
}: CdSelectProps) {
  if (selectType === 'filter') {
    return (
      <CdFilterSelect
        title={title}
        options={rest.options}
        value={rest.value}
        onChange={rest.onChange}
        style={rest.style}
        filterOption={rest.filterOption}
        filterSort={rest.filterSort}
      />
    );
  }

  return (
    <Row wrap={false} gutter={4} align="middle">
      <Col flex="auto">
        <Select
          allowClear={allowClear}
          showSearch={showSearch}
          filterOption={filterOption}
          filterSort={filterSort}
          notFoundContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                emptyText || I18nService.getString('No results found')
              }
              style={{ margin: 8 }}
            />
          }
          {...rest}
        />
      </Col>
      {addonAfter && (
        <Col style={{ alignSelf: 'stretch', height: 'auto' }}>{addonAfter}</Col>
      )}
    </Row>
  );
}

export const filterOption = (input: string, option: DefaultOptionType) =>
  // We support name as a string only, since label can be a ReactNode and that isn't compatiable with the filterOption function
  (option?.searchText || option?.label)
    ?.toLowerCase()
    .includes(input?.toLowerCase() || '');

export const filterSort = (a: DefaultOptionType, b: DefaultOptionType) =>
  sortString(a?.searchText || a?.label, b?.searchText || b?.label);
