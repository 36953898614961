import { useQuery, useQueryClient } from '@tanstack/react-query';

import { mainApi } from '../../api';
import { CategoryType } from '../../shared/models/category';
import NotificationService from '../../services/NotificationService';
import { I18nService } from '../../services/I18nService';
import { CdClientSideTableApiSearchParam } from '../../shared/components/cd-client-side-table/types';
import { taxonomiesQueries } from '../../shared/queries/taxonomies';

export const useTaxonomies = (type: CategoryType, usage?: string) => {
  const queryClient = useQueryClient();
  const query = useQuery(taxonomiesQueries.getAllByType(type, usage));

  const refreshTaxonomies = () => {
    queryClient.invalidateQueries(taxonomiesQueries.getAll());
  };

  return {
    taxonomies: query.data,
    refreshTaxonomies,
    isLoading: query.isLoading,
  };
};

export const useTaxonomiesClientside = (
  params: CdClientSideTableApiSearchParam
) => useQuery(taxonomiesQueries.getByParams(params));

export const useDeleteTaxonomy = () => {
  const queryClient = useQueryClient();
  const deleteTaxonomy = async (id: number) => {
    await mainApi.delete(`taxonomies/${id}`);
    await queryClient.invalidateQueries(taxonomiesQueries.getAll());
    NotificationService.notifySuccess(
      I18nService.getString('Booking page deleted')
    );
  };

  return { deleteTaxonomy };
};
