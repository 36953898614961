import React from 'react';

import ElementWrapper from './ElementWrapper';

import { CdEyeSlashIcon } from '@/react/shared/components/Icons';

export const AltText = ({ name }: { name: string }) =>
  name ? (
    <ElementWrapper>
      <CdEyeSlashIcon />
      <span>{name}</span>
    </ElementWrapper>
  ) : null;
