import { UpdateFile } from '../types/images.type';

import { mainApi } from '@/react/api';

export const updateFile = async ({ fileId, payload }: UpdateFile) => {
  const response = await mainApi.put(`/files/${fileId}`, payload);
  if (!response.ok) {
    throw response;
  } else {
    return response.data;
  }
};
