import { compact, isArray } from 'lodash';

import { I18nService } from '../../../services/I18nService';

import {
  clientToBackendVisibility,
  getInitialEventVisibility,
} from '@/react/calendar/helpers/visibility';
import {
  BackendEventVisibility,
  ClientEventVisibility,
} from '@/react/calendar/types/event';
import cdApp from '@/react/config';
import {
  AvailabilityTaxonomyFormData,
  FormBookingHours,
  FormDuration,
  TaxonomyBookingOption,
} from '@/react/settings/booking-pages/types/availability-taxonomy.types';
import {
  BookingHours,
  BookingOption,
  ITaxonomyTypes,
  Taxonomy,
  Weekdays,
} from '@/react/shared/models/taxonomy';

export const EMPTY_FORM_DATA = (
  bookingEnquiriesV2Enabled: boolean
): AvailabilityTaxonomyFormData => ({
  name: '',
  alias: '',
  availabilityType: bookingEnquiriesV2Enabled
    ? 'general-availability'
    : 'specific-availability',
  groups: [],
  churchIds: [],
  isBookable: false,
  bookingName: '',
  bookingDescription: '',
  automaticFollowUpForm: false,
  bookingOptions: [createNewBookingOption()],
  visibilityGroups: [],
  visibility: getInitialEventVisibility(),
  emailTemplates: {
    confirmation: I18nService.getString(
      'We have accepted your enquiry, as a next step, please fill out the following form.'
    ),
    rejection: I18nService.getString(
      'Unfortunately your enquiry will not be possible to accommodate. Please enquire another date and time instead.'
    ),
  },
});
export const createNewBookingOption = (): TaxonomyBookingOption => ({
  name: '',
  description: '',
  additionalResourceIds: [],
  allowWeeklySchedule: false,
  bookingHours: Object.fromEntries(
    Object.values(Weekdays).map((day) => [
      day,
      {
        enabled: false,
        data: [{ start: '08:00', end: '16:00' }],
      },
    ])
  ) as FormBookingHours,
  bookingMode: 'usersAndResources',
  showUsers: true,
  duration: {
    hours: 1,
    minutes: 0,
  },
  minimumNoticePeriod: {
    days: 0,
    hours: 0,
    minutes: 0,
  },
  bufferAfterEvent: {
    hours: 0,
    minutes: 0,
  },
  bufferBeforeEvent: {
    hours: 0,
    minutes: 0,
  },
  startTimeIncrement: 60,
  limitOnEnquiriesIsActive: false,
});
export const trimResourcePrefix = (resource?: string): number => {
  if (!resource) {
    return undefined;
  }
  return Number(resource.replace('resource-', ''));
};
const addResourcePrefix = (resource?: number) => {
  if (resource === undefined || resource === null) {
    return undefined;
  }
  return `resource-${resource}`;
};

const isWeeklyScheduleDefined = (bookingHours?: BookingHours): boolean => {
  if (!bookingHours) {
    return false;
  }
  return (
    Object.values(bookingHours).length !== 7 ||
    Object.values(bookingHours).some((item) => item.length !== 0)
  );
};

const minutesToDuration = (minutes: number): FormDuration => {
  let remainder = minutes;
  const days = Math.floor(remainder / (60 * 24));
  remainder %= 60 * 24;
  const hours = Math.floor(remainder / 60);
  remainder %= 60;

  return {
    days,
    hours,
    minutes: remainder,
  };
};

const backendVisibilityToClient = (
  visibility: BackendEventVisibility,
  groups?: number[]
): ClientEventVisibility => {
  if (visibility === BackendEventVisibility.PUBLIC) {
    return ClientEventVisibility.PUBLIC;
  } else if (visibility === BackendEventVisibility.PRIVATE) {
    return ClientEventVisibility.PRIVATE;
  } else {
    // backend visibility is 'internal'
    if ((groups?.length ?? 0) > 0) {
      return ClientEventVisibility.INTERNAL_GROUPS;
    }
    return ClientEventVisibility.INTERNAL_ALL;
  }
};

export const existingTaxonomyToFormData = (
  taxonomy: Taxonomy
): AvailabilityTaxonomyFormData => ({
  name: taxonomy.name,
  alias: taxonomy.alias ?? '',
  availabilityType: taxonomy.config?.type,
  groups: taxonomy.groups?.map((group) => Number(group.id)) ?? [],
  isBookable: true,
  bookingName: taxonomy.config?.publicName,
  bookingDescription: taxonomy.description ?? '',
  categoryId: (taxonomy.config?.event?.categoryIds ?? [])?.[0],
  churchIds: taxonomy.config?.event?.churchIds ?? [],
  visibility: backendVisibilityToClient(
    taxonomy.config?.event?.visibility,
    taxonomy.config?.event?.groupIds
  ),
  visibilityGroups: taxonomy.config?.event?.groupIds ?? [],
  automaticFollowUpForm: taxonomy.config?.automaticFollowUpForm ?? false,
  emailTemplates: taxonomy.config?.emailTemplates,
  bookingOptions:
    taxonomy.bookingOptions?.map((option) => ({
      id: option?.id,
      name: option.name ?? '',
      description: option.description ?? '',
      allowWeeklySchedule: isWeeklyScheduleDefined(option.config?.bookingHours),
      additionalResourceIds: compact(
        option.additionalResourceIds?.map(addResourcePrefix) ?? []
      ),
      resourceId: addResourcePrefix(option.mainResourceId),
      userIds: option.userIds ?? [],
      showUsers: option.config?.showUsers ?? true,
      bookingMode: option.config?.bookingMode,
      bookingHours: Object.fromEntries(
        Object.values(Weekdays).map((day) => {
          const existing = option.config?.bookingHours[day];
          if (existing && existing.length > 0) {
            return [
              day,
              {
                enabled: true,
                data: existing.map((e) => ({
                  start: e.start,
                  end: e.end,
                })),
              },
            ];
          } else {
            return [
              day,
              { enabled: false, data: [{ start: '08:00', end: '16:00' }] },
            ];
          }
        })
      ) as FormBookingHours,
      duration: minutesToDuration(option.config?.duration),
      minimumNoticePeriod: minutesToDuration(
        option.config?.minimumNoticePeriod
      ),
      startTimeIncrement: option.config?.startTimeIncrement,
      bufferBeforeEvent: minutesToDuration(option.config?.bufferBeforeEvent),
      bufferAfterEvent: minutesToDuration(option.config?.bufferAfterEvent),
      enquiryLimit: option.config?.enquiryLimit,
      limitOnEnquiriesIsActive: !!option.config?.enquiryLimit,
    })) ?? [],
});

const convertDurationToMinutes = (
  duration?: FormDuration
): number | undefined => {
  if (!duration) {
    return 0;
  }
  return (
    (duration.days || 0) * 60 * 24 +
    (duration.hours || 0) * 60 +
    (duration.minutes || 0)
  );
};

const convertFormDataBookingOptionToApi = (
  formData: AvailabilityTaxonomyFormData,
  optionFormData: TaxonomyBookingOption,
  existing?: BookingOption
): BookingOption => {
  const bookingHours = Object.fromEntries(
    Object.entries(optionFormData.bookingHours).map(([day, data]) => [
      day,
      data.enabled
        ? data.data.map((item) => ({
            start: item.start,
            end: item.end,
          }))
        : [],
    ])
  );

  const result: BookingOption = {
    ...(existing ?? {}),
    name: optionFormData.name,
    description: optionFormData.description,
    mainResourceId: trimResourcePrefix(optionFormData.resourceId),
    additionalResourceIds: compact(
      optionFormData.additionalResourceIds.map(trimResourcePrefix)
    ),
    userIds:
      (isArray(optionFormData.userIds)
        ? optionFormData.userIds
        : compact([optionFormData.userIds])) || [],
    config: {
      bookingHours,
      duration: convertDurationToMinutes(optionFormData.duration),
      bookingMode:
        formData?.availabilityType === 'general-availability'
          ? optionFormData.bookingMode
          : null,
      showUsers: optionFormData.showUsers,
      minimumNoticePeriod: convertDurationToMinutes(
        optionFormData.minimumNoticePeriod
      ),
      startTimeIncrement: Number(optionFormData.startTimeIncrement),
      bufferBeforeEvent: convertDurationToMinutes(
        optionFormData.bufferBeforeEvent
      ),
      bufferAfterEvent: convertDurationToMinutes(
        optionFormData.bufferAfterEvent
      ),
    },
  };

  // Conditionally added since backend doesn't support null
  if (optionFormData.limitOnEnquiriesIsActive) {
    result.config.enquiryLimit = optionFormData.enquiryLimit;
  }

  return result;
};
export const convertFormDataToTaxonomy = (
  taxonomy: Taxonomy | undefined,
  formData: AvailabilityTaxonomyFormData
): Partial<Taxonomy> => ({
  ...(taxonomy ?? {}),
  type: ITaxonomyTypes.availability,
  name: formData.name,
  alias: formData.alias,
  groups: formData.groups.map((id) => ({ id: Number(id) })),
  description: formData.bookingDescription,
  config: {
    ...(taxonomy?.config ?? {}),
    type: formData.availabilityType,
    isBookable: true,
    publicName: formData.bookingName ?? formData.name,
    automaticFollowUpForm: formData.automaticFollowUpForm,
    emailTemplates: formData.emailTemplates,
    event: {
      churchIds:
        formData.churchIds.length === 0
          ? [cdApp.organization.churches[0].id]
          : formData.churchIds,
      title: formData.bookingName,
      categoryIds: formData.categoryId ? [formData.categoryId] : [],
      groupIds:
        formData.visibility === 'internal-group'
          ? formData.visibilityGroups
          : [],
      visibility: clientToBackendVisibility(formData.visibility),
    },
  },
  bookingOptions: formData.bookingOptions.map((option) =>
    convertFormDataBookingOptionToApi(
      formData,
      option,
      taxonomy?.bookingOptions?.find((o) => o.id === option.id)
    )
  ),
});
