import { mainApi } from '../../api';
import { getTaxonomy } from '../../settings/services/categories';
import { CdClientSideTableApiSearchParam } from '../components/cd-client-side-table/types';
import { sortString } from '../utils/sorter';
import { Taxonomy } from '../models/taxonomy';

import { CdQueryDefinition } from './types';

export const taxonomiesQueries = {
  getAll: () => ({
    queryKey: ['taxonomies'],
    queryFn: async () => {
      const response = await mainApi.get<Taxonomy[]>('taxonomies');
      if (response.ok) {
        return response.data || [];
      }
      throw response;
    },
    staleTime: Infinity,
  }),
  getAllByType: <T extends Taxonomy>(type: string, usage?: string) => ({
    queryKey: ['taxonomies', type, usage],
    queryFn: async () => {
      const response = await mainApi.get<T[]>('taxonomies', { type, usage });
      if (response.ok) {
        return (response.data || []).sort((a, b) => sortString(a.name, b.name));
      }
      throw response;
    },
    staleTime: Infinity,
  }),
  getById: (id: number) => ({
    queryKey: ['taxonomies', id],
    queryFn: () => (id ? getTaxonomy(id!) : null),
  }),
  getByParams: (params: CdClientSideTableApiSearchParam) => ({
    queryKey: ['taxonomies', params.extraData.type, params.extraData.search],
    queryFn: async () => {
      const response = await mainApi.get<Taxonomy[]>(
        `taxonomies?type=${params.extraData.type}`
      );
      if (response.ok) {
        const search = params.extraData.search?.toLowerCase();

        const searchIsIncluded = (item: Taxonomy) => {
          if (search?.trim().length === 0 || !search) {
            return true;
          }

          const isNameIncluded = item.name?.toLowerCase().includes(search);
          const isPublicNameIncluded = item.config?.publicName
            ?.toLowerCase()
            .includes(search);

          return isNameIncluded || isPublicNameIncluded;
        };

        const filtered = (response.data || []).filter(searchIsIncluded);

        return {
          items: filtered,
          total: filtered.length,
        };
      }
      throw response;
    },
  }),
} satisfies CdQueryDefinition;
