import { Form, FormInstance } from 'antd';
import { useEffect } from 'react';
import { intersection } from 'lodash';

import { AvailabilityTaxonomyFormData } from '../types/availability-taxonomy.types';
import { useGroups } from '../../../organization/store/organization';

export const useOptionFormData = (
  form: FormInstance<AvailabilityTaxonomyFormData>,
  optionIndex: number
) => {
  const groupIds = Form.useWatch(['groups'], form);
  const availabilityType = Form.useWatch('availabilityType', form);

  const { groupMemberIds, isLoading } = useGroups(groupIds);

  const limitOnEnquiriesIsActive = Form.useWatch([
    'bookingOptions',
    optionIndex,
    'limitOnEnquiriesIsActive',
  ]);
  const allowWeeklySchedule = Form.useWatch(
    ['bookingOptions', optionIndex, 'allowWeeklySchedule'],
    form
  );
  const bookingMode = Form.useWatch(
    ['bookingOptions', optionIndex, 'bookingMode'],
    form
  );
  const resourceId = Form.useWatch(
    ['bookingOptions', optionIndex, 'resourceId'],
    form
  );
  const additionalResourceIds = Form.useWatch(
    ['bookingOptions', optionIndex, 'additionalResourceIds'],
    form
  );

  useEffect(() => {
    if (
      limitOnEnquiriesIsActive &&
      !form.getFieldValue(['bookingOptions', optionIndex, 'enquiryLimit'])
    ) {
      form.setFieldValue(
        ['bookingOptions', optionIndex, 'enquiryLimit', 'value'],
        1
      );
      form.setFieldValue(
        ['bookingOptions', optionIndex, 'enquiryLimit', 'unit'],
        'day'
      );
    }
  }, [form, limitOnEnquiriesIsActive, optionIndex]);

  useEffect(() => {
    // Ensure only group members are selected
    const userIds = form.getFieldValue([
      'bookingOptions',
      optionIndex,
      'userIds',
    ]);
    if (!isLoading) {
      form.setFieldValue(
        ['bookingOptions', optionIndex, 'userIds'],
        intersection(userIds, groupMemberIds)
      );
    }
  }, [form, groupMemberIds, isLoading, optionIndex]);

  return {
    bookingMode,
    groupMemberIds,
    allowWeeklySchedule,
    limitOnEnquiriesIsActive,
    availabilityType,
    resourceId,
    additionalResourceIds,
  };
};
