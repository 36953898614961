export interface IImageCropInstance extends IImageCropAttributes {
  dataValues: IImageCropAttributes;
}

export interface IImageCropAttributes {
  id: string;
  context: string;
  styleName: string;
  top: number;
  bottom: number;
  left: number;
  right: number;
  createdAt: string;
  updatedAt: string;
}

export interface CdrFile {
  id: number;
  // User generated title of the file
  title: string;
  visibility: FileVisibility;
  organizationId: number;
  folderId: number;
  groupId: number;
  authorId: number;
  createdAt: string;
  updatedAt: string;
  credits: string;
  type: string;
  tags: [];
  downloadUrl?: string;
  fileUrl: string;
  uuid: string;
  fileInfo: {
    filesize: string;
    filetype: {
      type: string;
      translated_type: string;
      class: string;
      style: true;
    };
    // fileName is the actual safe file name on disk
    filename: string;
    fileuri: string;
    fid: number;
    filemime: string;
    fileSizeBytes: number;
    thumbnail: {
      url: string;
    };
    medium: {
      url: string;
    };
    large: {
      url: string;
    };
    azul: {
      url: string;
    };
    full: {
      url: string;
    };
  };
  permission: { canEdit: boolean; canDelete: boolean; canView: boolean };
  exceededPixelSize: number;
  altText: string;
}

export interface Folder {
  title: string;
  value: number;
  id: number;
  groupId: string;
  parentFolderId?: number;
  children?: Folder[];
}

export enum FileVisibility {
  GROUP = 'group',
  PUBLIC = 'web',
}
