import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import {
  EditFileButton,
  ImageFileLink,
} from '@/react/files/components/EditFileButton';

angular
  .module('cdApp.intranet')
  .component(
    'cdrEditFile',
    react2angular(
      sharedContext.use(EditFileButton),
      ['fileId', 'tagsList', 'canEdit'],
      []
    )
  );
angular
  .module('cdApp.intranet')
  .component(
    'cdrImageFileTitleLink',
    react2angular(
      sharedContext.use(ImageFileLink),
      ['fileId', 'tagsList', 'title', 'fileThumbnailUrl'],
      []
    )
  );
